import * as actionTypes from "./constants";
import * as Service from "./services";

const getConsultancies = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_CONSULTANCIES_REQUEST });
            const res = await Service.getConsultancies(params);
            if (res && res.status === 200) {
                const getConsultancyData = res.data;
                if (getConsultancyData) {
                    dispatch({ type: actionTypes.GET_CONSULTANCIES_SUCCESS, response: getConsultancyData });
                } else {
                    dispatch({ type: actionTypes.GET_CONSULTANCIES_FAILURE, error: getConsultancyData });
                }
            } else {
                dispatch({ type: actionTypes.GET_CONSULTANCIES_FAILURE, error: res.response && res.response.data });
            }
        } catch (e) {
            dispatch({ type: actionTypes.GET_CONSULTANCIES_FAILURE, error: e.response && e.response.data });
        }
    };
};

const getListForCommonFilterForConsultancy = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.GET_LIST_FOR_COMMON_FILTER_REQUEST });
            const res = await Service.getListForCommonFilterForConsultancy(params);
            if (res && res.status === 200) {
                if (res.data) {
                    dispatch({ type: actionTypes.GET_LIST_FOR_COMMON_FILTER_SUCCESS, response: res.data });
                } else {
                    dispatch({ type: actionTypes.GET_LIST_FOR_COMMON_FILTER_FAILURE, error: res.data });
                }
            } else {
                dispatch({ type: actionTypes.GET_LIST_FOR_COMMON_FILTER_FAILURE, error: res.data });
            }
        } catch (e) {
            dispatch({ type: actionTypes.GET_LIST_FOR_COMMON_FILTER_FAILURE, error: e.response && e.response.data });
        }
    };
};

const exportConsultancy = params => {
    return async dispatch => {
        try {
            dispatch({ type: actionTypes.EXPORT_CONSULTANCY_TABLE_REQUEST });
            const response = await Service.exportConsultancy(params);
            if (response && response.data) {
                const text = await new Response(response.data).text();
                if (text && text.split('"')[1] === "error") {
                    dispatch({ type: actionTypes.EXPORT_CONSULTANCY_TABLE_SUCCESS, response: { error: text.split('"')[3] } });
                    return true;
                } else {
                    dispatch({ type: actionTypes.EXPORT_CONSULTANCY_TABLE_SUCCESS, response: {} });
                }
            }
            const { data } = response;
            const name = response.headers["content-disposition"].split("filename=");
            const fileName = name[1].split('"')[1];
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", `${fileName}`); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (e) {
            dispatch({
                type: actionTypes.EXPORT_CONSULTANCY_TABLE_FAILURE,
                error: e.response && e.response.data
            });
        }
    };
};

const updateConsultancyEntityParams = entityParams => {
    return async dispatch => {
        try {
            if (entityParams) {
                dispatch({
                    type: actionTypes.UPDATE_CONSULTANCY_ENTITY_PARAMS_SUCCESS,
                    response: entityParams
                });
            }
        } catch (e) {
            dispatch({
                type: actionTypes.UPDATE_CONSULTANCY_ENTITY_PARAMS_FAILURE,
                error: entityParams
            });
        }
    };
};

export default {
    getConsultancies,
    getListForCommonFilterForConsultancy,
    exportConsultancy,
    updateConsultancyEntityParams
};
