import { logbookGateWay } from "../../../services/authorizationService";
import * as serviceEndPoints from "../../../config/serviceEndPoints";

export const getConsultancies = params => logbookGateWay.get(serviceEndPoints.exportHistoryEndPoints.getHistory, { params });
export const getListForCommonFilterForConsultancy = params =>
    logbookGateWay.get(`${serviceEndPoints.exportHistoryEndPoints.getHistory}/get_list`, {
        params
    });

export const exportConsultancy = params =>
    logbookGateWay.get(`${serviceEndPoints.exportHistoryEndPoints.getHistory}/export_xl`, {
        method: "GET",
        responseType: "blob",
        params
    });
