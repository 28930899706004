export const consultancyTableData = {
    keys: ["zip_file_name", "user", "status", "created_at", "updated_at"],
    config: {
        zip_file_name: {
            isVisible: true,
            label: "File Name",
            class: "",
            searchKey: "export_log.zip_file_name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "export_log",
            commonSearchKey: "export_log",
            commonSearchObjectKey: "zip_file_name"
        },
        user: {
            isVisible: true,
            label: "User",
            class: "reg-name",
            searchKey: "user.name",
            type: "object",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "user",
            commonSearchKey: "user",
            commonSearchObjectKey: "name"
        },
        status: {
            isVisible: true,
            label: "Status",
            class: "reg-name",
            searchKey: "user.name",
            type: "string",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "user",
            commonSearchKey: "user",
            commonSearchObjectKey: "name"
        },
        created_at: {
            isVisible: true,
            label: "Created At",
            class: "",
            searchKey: "export_log.created_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "export_log",
            commonSearchKey: "export_log",
            commonSearchObjectKey: "created_at"
        },
        updated_at: {
            isVisible: true,
            label: "Updated At",
            class: "",
            searchKey: "export_log.updated_at",
            type: "date",
            hasWildCardSearch: true,
            hasSort: true,
            hasCommonSearch: false,
            getListTable: "export_log",
            commonSearchKey: "export_log",
            commonSearchObjectKey: "updated_at"
        }
    },
    data: []
};
